$breakpointXXs: 450px;
$breakpointXs: 600px;
$breakpointSm: 768px;
$breakpointMd: 992px;
$breakpointLg: 1200px;
$breakpointXl: 1500px;
$breakpointXXl: 2000px;
 
$main: #FD1219;
$mainLight: #ffba80;
$background: #ececec;
$gray: #757575;

$font: "Lato", sans-serif;

@mixin media($width) {
  @media screen and (min-width: $width) {
    @content;
  }
}

@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');
@import "reset";
@import "layout";
@import "universal";
@import "header";
@import "home";