html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

html {
  font-size: 62.5%;
}

body {
  position: relative;
  min-height: 100vh;
  font-weight: 400;
  color: #101010;
  font-size: 1.6rem;
  font-family: $font;
  box-sizing: border-box;
  line-height: 1.25;
  overflow-anchor: none;
  @include media($breakpointMd) {
    line-height: 1.375;
  }
  @include media($breakpointLg) {
    border-top-width: 110px;
  }
  @include media($breakpointXl) {
    border-top-width: 178px;
  }
}

h1 {
  font-weight: 700;
  font-size: 1.8rem;
  margin: 1em 0;
  @include media($breakpointLg) {
    font-size: 2.4rem;
  }
}

h2 {
  margin-bottom: 1em;
  &:only-child {
    margin-bottom: 0;
  }
  font-size: 2rem;
  @include media($breakpointLg) {
    margin-bottom: auto;
    font-size: 2.8rem;
  }
  @include media($breakpointXl) {
    font-size: 3.4rem;
  }
}

h3 {
  font-size: 1.8rem;
  font-weight: 700;
  @include media($breakpointLg) {
    font-size: 2.2rem;
  }
}

h4 {
  font-size: 18px;
  font-weight: 600;
  @include media($breakpointLg) {
    font-size: 20px;
  }
}

a {
  text-decoration: none;
  color: inherit;
}

sup {
  vertical-align: super;
  font-size: smaller;
}

p {
  margin: 1em 0;
  line-height: 1.5em;
}

img {
  max-width: 100%;
}

strong {
  font-weight: 600;
}

em {
  font-style: italic;
}

select {
  border: 0;
  display: block;
  width: 100%;

  &:focus {
    outline: 0;
  }
}

textarea {
  height: 140px;
  resize: none;
  font-family: $font;
}
label {
  text-transform: uppercase;
  font-size: 1.4rem;
}

button {
  border: 0;
  cursor: pointer;
  background: transparent;
  &:focus {
    outline: 0;
  }
}

textarea {
  font-family: $font;
}

address {
  line-height: 1.5em;
}