.header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
}

.logo {
  width: 150px;
  height: auto;

  @include media($breakpointLg) {
    width: 275px;
  }
}

.burger-checkbox{
  display: none;

  &__bar {
    display: block;
    position: relative;
    width: 30px;
    height: 3px;
    background-color: #fff;
    transition: 300ms;
    z-index: 1000;

    @include media($breakpointMd) {
      display: none;
    }

    &:before,
    &:after {
      content: "";
      position: absolute;
      left: 0;
      width: 100%;
      height: 3px;
      background-color: #fff;
    }

    &:before {
      top: -8px;
      transition: 150ms 150ms top, 150ms transform, 300ms background-color;
    }

    &:after {
      bottom: -8px;
      transition: 150ms 150ms bottom, 150ms transform, 300ms background-color;
    }
  }

  &:checked ~ &__bar {
    position: fixed;
    right: 20px;
    background-color: transparent;
    
    &:before {
      top: 0;
      background-color: #101010;
      transform: rotate(45deg);
      transition: 150ms top, 150ms 150ms transform;
    }
    &:after {
      bottom: 0;
      background-color: #101010;
      transform: rotate(-45deg);
      transition: 150ms bottom, 150ms 150ms transform;
    }
  }

  &:checked ~ .navbar{
    transform: translateX(0);
  }
}

// .burger {
//   position: relative;
//   width: 30px;
//   height: 40px;
//   z-index: 1000;

//   @include media($breakpointMd) {
//     display: none;
//   }

//   &__bar {
//     display: block;
//     position: relative;
//     width: 100%;
//     height: 3px;
//     background-color: #fff;
//     transition: 300ms;

//     &:before,
//     &:after {
//       content: "";
//       position: absolute;
//       left: 0;
//       width: 100%;
//       height: 3px;
//       background-color: #fff;
//     }

//     &:before {
//       top: -8px;
//       transition: 150ms 150ms top, 150ms transform, 300ms background-color;
//     }

//     &:after {
//       bottom: -8px;
//       transition: 150ms 150ms bottom, 150ms transform, 300ms background-color;
//     }
//   }

//   &--active &__bar {
//     background-color: transparent;

//     &:before {
//       top: 0;
//       transform: rotate(45deg);
//       transition: 150ms top, 150ms 150ms transform;
//     }
//     &:after {
//       bottom: 0;
//       transform: rotate(-45deg);
//       transition: 150ms bottom, 150ms 150ms transform;
//     }
//   }
// }

.navbar {
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  transition: 300ms;
  transform: translateX(100%);
  z-index: 999;

  &--active {
    transform: translateX(0);
  }


  @include media($breakpointMd) {
    position: static;
    transform: translateX(0);
  }
}

.nav {
  padding-top: 50px;
  height: 100%;
  background-color: #fff;;

  @include media($breakpointMd) {
    padding-top: 0;
    background-color: transparent;
  }

  &__link {
    padding: 20px;
    display: block;
    text-align: right;
    font-weight: 500;
    transition: 200ms;
    
    @include media($breakpointMd) {
      padding: 20px 50px;
      text-align: center;
      color: #fff;
    }
    @include media($breakpointLg) {
      padding: 40px 50px;
      text-align: center;
    }


  }

}
