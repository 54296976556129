.slider{
    position: relative;
    background: center /cover no-repeat;
    background-image: url('../optimized/slider.png');
    height: 100vh;
    color: #fff;

    &__box{
        width: 100%;
        max-width: 1000px;
    }
    
    &__header{
        font-size: 2.2rem;
        font-weight: 700;
        
        @include media($breakpointLg){
            font-size: 3.6rem;

        }
    }
    
    &__content{
        font-size: 2.2rem;
        @include media($breakpointLg){
            font-size: 3.6rem;
    
        }
    }

    &__caption{
        position: absolute;
        bottom: 5px;
        right: 5px;
    }
} 


.gallery{
    margin: 30px 0;
    
    grid-row-gap: 50px;
    grid-column-gap: 100px;

    &__item{
        height: 260px;
    }

    &__caption{
        font-size: 1rem;
    }
}

.footer{
    &__icon{
        padding-left: 40px;
        background: left 5px /20px 18px no-repeat;
        box-sizing: border-box;

        &--place{
            background-size: 15px auto;
            background-image: url('../optimized/pin.svg');
        }
        &--phone{
            background-image: url('../optimized/phone.svg');
        }
        &--mail{
            background-image: url('../optimized/mail.svg');
        }
    }

    
}

.credits{
    margin-bottom: 10px;
    font-size: 1.8rem;
    font-weight: 700;
    text-transform: uppercase;
}